import { useEffect } from 'react'
import { type Toast } from '#app/sessions/toast.server.ts'
import { notify } from '#app/utils/toasts.tsx'

export function useToast(toast?: Toast | null) {
	useEffect(() => {
		if (toast) {
			setTimeout(() => {
				notify(toast.description, {
					toastId: toast.id,
					type: toast.type,
					autoClose: toast.duration,
					delay: toast.delay,
					position: toast.position,
					isLoading: toast.isLoading,
					progress: toast.progress,
					updateId: toast.updateId,
				})
			}, 0)
		}
	}, [toast])
}
