import { Card, Image } from 'react-bootstrap'
import logo from '~/assets/img/kiz-logo.svg'
import UIFooter from '~/components/ui/footer.tsx'

export function UnderConstruction() {
	return (
		<main>
			<div className="px-4 py-5 px-md-5 text-center text-lg-start min-vh-95">
				<div className="container">
					<div className="row gx-lg-5 align-items-center">
						<div className="my-5 mb-lg-0">
							<Card className="border-0">
								<Card.Body className="p-5 align-self-center align-items-center w-50">
									<Image
										src={logo}
										alt="KIZ Logo"
										className="text-center"
										style={{
											height: '12rem',
											display: 'block',
											margin: '0 auto',
										}}
									/>
									<h1 className="mb-2 text-center">Under Construction</h1>
									<p className="text-center">
										We are currently making changes to the features and
										functionality of this service. Please check back with us
										later.
									</p>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>
			</div>
			<UIFooter />
		</main>
	)
}
